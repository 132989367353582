<template>
  <div>
    <v-container v-if="order.userId == currentUser.id">
      <v-row id="printMe">
        <v-col>
          <v-card outlined>
            <v-toolbar v-if="!onPrint" flat dark color="teal">
              <v-btn to="/account/orders" text icon>
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="readyToPrint" text icon>
                <v-icon>print</v-icon>
              </v-btn>
            </v-toolbar>
            <v-toolbar extended v-if="onPrint" flat>
              <img class="ml-5 mt-5" height="auto" width="auto" :src="logo" />
              <v-spacer></v-spacer>
              <h2>Order Details</h2>
            </v-toolbar>
            <v-row>
              <v-col :cols="cols" sm="4">
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>account_circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{order.user.name}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-phone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{order.user.mobile}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-col>
              <v-col :cols="cols" sm="4">
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-map-marker-radius</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{order.user.address}}</v-list-item-title>
                        <div>{{order.user.city}}</div>
                        <div>{{order.user.pincode}}</div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-col>
              <v-col :cols="cols" sm="4">
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-codepen</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{order.orderId}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-calendar-clock</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{order.createdAt.toDate() | formatDate}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-timer-sand</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize">{{order.status}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card outlined>
            <v-list>
              <v-list-item>
                <v-list-item-avatar tile></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Product</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>Price</v-list-item-title>

                  <!-- <v-list-item-subtitle>Quantity</v-list-item-subtitle> -->
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-title>Total</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item-group v-for="product in order.products" :key="product.id">
                <v-list-item :to="'/products/category/'+ product.slug">
                  <v-list-item-avatar tile>
                    <v-img :src="product.images[0]"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="product.name"></v-list-item-title>
                    <v-list-item-subtitle v-text="'SKU: '+product.sku"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{product.price | currency({
                      symbol: '',
                      fractionCount: 0
                      })}}
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="'Qty: '+product.quantity"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-title>
                      {{totalOfQty(product) | currency({
                      symbol: '',
                      fractionCount: 0
                      })}}
                    </v-list-item-title>
                    <!-- <v-list-item-subtitle v-text="'Qty: '+product.quantity"></v-list-item-subtitle> -->
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </v-list-item-group>
              <v-list-item>
                <v-spacer></v-spacer>
                <v-list-item-content>
                  <v-list-item-title class="title">Total Amount:</v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-title class="title">{{total | currency}}</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      id: this.$route.params.id,
      logo: require("@/assets/logo.png"),
      cols: "4",
      onPrint: false,
      // order: null,
      status: [
        {
          text: "New",
          value: "new"
        },
        {
          text: "On Hold",
          value: "on hold"
        },
        {
          text: "Confirmed",
          value: "confirmed"
        },
        {
          text: "Processing",
          value: "processing"
        },
        {
          text: "Ready to Ship",
          value: "ready to ship"
        },
        {
          text: "Shipped",
          value: "shipped"
        },
        {
          text: "Delivered",
          value: "delivered"
        },
        {
          text: "Canceled",
          value: "canceled"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getOrderList", "currentUser"]),
    total() {
      return this.order.products.reduce((total, product) => {
        return product.quantity * product.price + total;
      }, 0);
    },
    order() {
      return this.getOrderList.find(order => {
        return order.orderId == this.id;
      });
    }
  },
  methods: {
    async readyToPrint() {
      const prepareToPrint = () => {
        this.cols = "4";
        this.onPrint = true;
        return this.cols == "4" && this.onPrint == true ? true : false;
      };
      await prepareToPrint();
      this.print();
    },
    print() {
      // Pass the element id here
      return this.$htmlToPaper("printMe", () => {
        this.cols = "12";
        this.onPrint = false;
      });
    },
    totalOfQty(product) {
      return product.quantity * product.price;
    }
  }
};
</script>
